html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.preview {
  height: 3em;
  flex: 0.25;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid black;
}

.preview svg {
  width: 100%;
  margin-bottom: 0.5em;
}

.stage {
  overflow-x: scroll;
  overflow-y: hidden;

  height: 10em;
  flex: 1;
  display: flex;
}

.svg_wrapper {
  flex: 1 1;
}

.control {
  padding: 1em;
  display: grid;
  gap: 0.5em;
}

.time {
  grid-column: 1 / 3;
  display: flex;
}

.time span {
  margin-right: 2em;
}

.time input {
  flex: 1;
}

.time button {
  width: 5em;
}

.group {
  grid-column: 1;
}

.data {
  grid-column: 2;
  justify-self: end;
}

.data button {
  margin-left: 1em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
